
import {Map} from 'immutable'
import {aliveActiveActiveEverplan, allOwnersActiveAndAlive, ownerIdFromSeat} from './clientsEverplanStatus'
import {humanizedFullName, humanizedFullNames, humanizedFirstNamesWithConjunction} from './nameTools'

export const fullName = ({clientSeats, isHousehold, owners, owner}) => (
  aliveActiveActiveEverplan({clientSeats, isHousehold, owners}) ?
    humanizedFullNames(owners) :
    humanizedFullName(owner)
)

export const firstName = ({clientSeats, isHousehold, owners, owner}) => (
  aliveActiveActiveEverplan({clientSeats, isHousehold, owners}) ?
    humanizedFirstNamesWithConjunction(owners, 'and') :
    owner.get('first-name')
)

export const removedClientName = ({owner, clientSeats, owners}) => {
  if (owner.name)
    return owner.name
  else if (allOwnersActiveAndAlive({clientSeats, owners}))
    return humanizedFullNames(owners)
  else
    return humanizedFullName(owner)
}

export const clientSeatByOwner = ({clientSeats, owner}) => (
  clientSeats.find(seat => ownerIdFromSeat(seat) === owner.get('id')) || Map()
)

export const otherClientSeat = ({clientSeats, currentSeat}) => clientSeats.find(seat => parseInt(seat.get('id'), 10) !== parseInt(currentSeat.get('id'), 10)) || Map()

export const otherOwner = ({currentOwner, owners}) => (
  owners.find(owner => owner.get('id') !== currentOwner.get('id')) || Map()
)

export const clientSeatById = (seats, id) => (
  seats.find(seat => parseInt(seat.get('id'), 10) === parseInt(id, 10)) || Map()
)

export const blockClientRemoval = ({clientSeats, seat}) => (
  seat.get('status') === 'Active' && otherClientSeat({clientSeats, currentSeat: seat}).get('status') !== 'Active'
)

export const blockRemovalWarningText = ({ownerName, otherOwnerName}) => (
  `Please remove ${otherOwnerName} before removing ${ownerName}.`
)

export const removedClientVerb = ({clientSeats, owners}) => (
  clientSeats.size > 1 && allOwnersActiveAndAlive({clientSeats, owners}) ?
    'have' :
    'has'
)
