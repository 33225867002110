import moment from 'moment'
import './jiclContactInformation.scss'

const JiclContactInformation = (props) => (
  <div className='jicl-contact-information'>
    <div className='name'>{props.userName}'s Just-In-Case List</div>
    <div className='updated-at'>
      Updated {moment(props.lastUpdatedAt).format('MMM DD, YYYY')}
    </div>
    <div className='description'>
      Here's some important information about me that can be helpful in the
      event of an emergency.
    </div>
  </div>
)

export default JiclContactInformation
