import PropTypes from 'prop-types'
import React from 'react'


export default class CollapsibleSection extends React.Component {
  constructor() {
    super()

    this.state = {displaySection: false}

    this.toggleSection = this.toggleSection.bind(this)
  }
  toggleSection(event) {
    if (event)
      event.preventDefault()
    this.setState({displaySection: !this.state.displaySection})
  }

  render() {
    return (
      <article className='collapsible-section'>
        <h2 className='collapsible-section-heading'>{this.props.heading}</h2>
        <a className='toggle-section' onClick={this.toggleSection}>{this.state.displaySection ? 'Hide' : 'Show'}</a>
        {this.state.displaySection && this.props.children}
      </article>
    )
  }
}

CollapsibleSection.propTypes = {
  heading: PropTypes.string
}
