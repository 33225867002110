/** @class ActiveRequestingApprovalRemove
 *
 * @desc
 * This is a version of NonActiveActiveHouseholdRemoveModal that uses v1
 * endpoint since for active/requesting approval household in v2, we don't return both owners.
 */

/* @todo @todo Remove this and use NonActiveActiveHouseholdRemoveModal once
* we modify v2 to always return both owners of a household for a
* requesting approval/active household --TK
*/
import {Map} from 'immutable'
import PropTypes from 'prop-types'

import {blockClientRemoval, blockRemovalWarningText, clientSeatById} from '../../../../../lib/removeClientsTools'
import BlockRemovalWarning from './BlockRemovalWarning'
import RemoveClientModal from './RemoveClientModal'

const ActiveRequestingApprovalRemove = props => {
  const owners = props.household.data.clients
  const {clientSeats} = props
  const activeOwner = owners.find(owner => owner.status === 'Active')
  const inactiveOwner = owners.find(owner => owner.status !== 'Active')
  return (
    <div className='active-requesting-approval-remove'>
      {owners.map(owner => {
        const disabled = blockClientRemoval({
          clientSeats,
          seat: clientSeatById(clientSeats, owner.client_seat_id)
        })
        return (
          <div key={owner.client_seat_id}>
            <RemoveClientModal
              {...props}
              buttonText={`Remove ${owner.first_name}`}
              clientSeat={clientSeatById(clientSeats, owner.client_seat_id)}
              disabled={disabled}
              owner={owner}
              key={owner.client_seat_id}
              fullName={owner.name}
              firstName={owner.first_name}
            />
            <BlockRemovalWarning
              disabled={disabled}
              warningText={blockRemovalWarningText({
                ownerName: activeOwner.name,
                otherOwnerName: inactiveOwner.name
              })}
            />
          </div>
        )
      })}
    </div>
  )
}


ActiveRequestingApprovalRemove.propTypes = {
  clientSeats: PropTypes.instanceOf(Map),
  household: PropTypes.shape({
    data: PropTypes.object
  })
}

export default ActiveRequestingApprovalRemove
