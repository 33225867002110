import PropTypes from 'prop-types'

import './blockRemovalWarning.scss'

const BlockRemovalWarning = ({warningText, disabled}) => {
  if (!disabled)
    return null
  return (
    <p className='block-removal-warning'>{warningText}</p>
  )
}

BlockRemovalWarning.propTypes = {
  disabled: PropTypes.bool,
  warningText: PropTypes.string
}

export default BlockRemovalWarning
