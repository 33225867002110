import {List} from 'immutable'
import PropTypes from 'prop-types'

import {activeRequestingApprovalEverplan} from '../../../../../lib/clientsEverplanStatus'
import {blockClientRemoval, blockRemovalWarningText, clientSeatByOwner, otherOwner} from '../../../../../lib/removeClientsTools'
import {humanizedFullName} from '../../../../../lib/nameTools'

import ActiveRequestingApprovalRemove from './ActiveRequestingApprovalRemove'
import BlockRemovalWarning from './BlockRemovalWarning'
import RemoveClientModal from './RemoveClientModal'

const NonActiveActiveHouseholdRemoveModal = props => {
  const {clientSeats, owners} = props
  if (activeRequestingApprovalEverplan({...props}))
    return <ActiveRequestingApprovalRemove {...props} />
  return (
    <div className='non-active-active-household-remove-modal'>
      {owners.map(owner => {
        const disabled = blockClientRemoval({
          clientSeats,
          seat: clientSeatByOwner({clientSeats, owner})
        })
        return (
          <div key={owner.get('id')}>
            <RemoveClientModal
              {...props}
              buttonText={`Remove ${owner.get('first-name')}`}
              clientSeat={clientSeatByOwner({clientSeats, owner})}
              disabled={disabled}
              owner={owner}
              fullName={humanizedFullName(owner)}
              firstName={owner.get('first-name')}
            />
            <BlockRemovalWarning
              disabled={disabled}
              warningText={blockRemovalWarningText({
                ownerName: humanizedFullName(owner),
                otherOwnerName: humanizedFullName(otherOwner({currentOwner: owner, owners}))
              })}
            />
          </div>
        )
      })}
    </div>
  )
}

NonActiveActiveHouseholdRemoveModal.propTypes = {
  clientSeats: PropTypes.instanceOf(List),
  owners: PropTypes.instanceOf(List)
}

export default NonActiveActiveHouseholdRemoveModal
