import {Map} from 'immutable'
import PropTypes from 'prop-types'
import {Component} from 'react'

import {BasicButton} from '../../../../../shared_components/ButtonTypes'
import RemoveActiveClientWarnings from './RemoveActiveClientWarnings'
import RemoveClientInvitationWarning from './RemoveClientInvitationWarning'

class RemoveFromPro extends Component {
  constructor() {
    super()
    this.removeFromPro = this.removeFromPro.bind(this)
  }

  removeFromPro() {
    this.props.removeClientFromPro(this.props.clientSeat.get('id'), this.props.owner)
  }

  render() {
    const props = this.props
    return (
      <div className='remove-from-pro'>
        <BasicButton
          loading={props.processing}
          onClick={this.removeFromPro}>
          Remove from Everplans Professional
        </BasicButton>
        <ul>
          <li>{props.firstName} will be removed from your Everplans Professional program.</li>
          <RemoveClientInvitationWarning status={props.status} />
          <RemoveActiveClientWarnings firstName={props.firstName} status={props.status} />
        </ul>
      </div>
    )
  }
}

RemoveFromPro.propTypes = {
  clientSeat: PropTypes.instanceOf(Map),
  firstName: PropTypes.string,
  owner: PropTypes.instanceOf(Map),
  processing: PropTypes.bool,
  removeClientFromPro: PropTypes.func,
  status: PropTypes.string
}

export default RemoveFromPro
